import { defineStore } from 'pinia';

export const useVideoStore = defineStore('video', {
  state () {
    return {
      video: null
    };
  },

  getters: {
    getVideo () {
      return this.video
    }
  },
  actions: {
    setVideo(url) {
      console.log(url, ' url');
      this.video = url; // + '&output=embed';
    },
    hideVideo () {
      this.video = null;
    }
  },
});
