<template>
  <div class="video-overlay-holder"
       v-if="getVideo"
       @click="hideVideo"
  >
    <div class="video-holder">
      <iframe  class="video-player"
               allowfullscreen="allowfullscreen"
               allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
               :src="getVideo" />
    </div>
  </div>
</template>
<script>

import {useVideoStore} from "~/store/VideoStore";

export default {
  data () {
    return {
    };
  },
  mounted () {
    document.body.prepend(this.$el);
  },
  computed: {
    getVideo () {
       return useVideoStore().getVideo

    }
  },
  methods: {
    hideVideo () {
      console.log('hide video');
      useVideoStore().hideVideo();
    }
  },
};
</script>
<style scoped>

.video-overlay-holder {
  position: fixed;
  background-color: rgba(0,0,0,0.8);
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 700px) {

}

.video-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 1200px;
  aspect-ratio: 16/9;
}

.video-player {
  width: 100%;
  height: 100%;
}

</style>
